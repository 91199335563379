import React from "react";
import { Link } from "gatsby";
import BusinessForms_Hero from "../../../assets/img/business-forms-hero.jpg";
import laser_forms from "../../../assets/img/LaserContinuousForms_Thumbnail.jpg";
import invoices from "../../../assets/img/general-purpose-manual.jpg";
import sales_forms from "../../../assets/img/sales-forms.jpg";
import work_orders from "../../../assets/img/market-specific-forms.jpg";
import proposals from "../../../assets/img/proposals.jpg";
import time_tickets from "../../../assets/img/time-tIckets.jpg";
import purchase_orders from "../../../assets/img/purchase-orders.jpg";
import gift_certificates from "../../../assets/img/gift-certificates.jpg";
import receipt_books from "../../../assets/img/receipt-books.jpg";
import shipping_products from "../../../assets/img/shipping-products.jpg";
import custom_forms from "../../../assets/img/shipping-products.jpg";

import styles from "../style/styles.module.css";

export default function BusinessForms() {
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={styles.content}>
          <h1>Business Forms</h1>
          <h2>Find forms for every customer here.</h2>
          <p className={styles.productParag}>
            Solutions to fit all business needs, formats and budgets.
          </p>
          <img
            className={styles.hero}
            src={BusinessForms_Hero}
            alt="Business forms Image"
          />

          <p className={styles.productMargin}>
            Meet more business needs with a wide range of competitively priced
            forms that help customers capture essential information and operate
            more efficiently.
          </p>
          <hr className={styles.productHr} />
        </div>
      </div>

      <div className={styles.row}>
        <div className={styles.content}>
          <h2>Product Category Highlights</h2>
          <div className={styles.cards}>
            <div className={styles.card}>
              <img src={laser_forms} alt="Laser forms Image"></img>
              <Link to="/products/business-forms/laser-forms/">
                Laser Forms
              </Link>
            </div>
            <div className={styles.card}>
              <img src={invoices} alt="Invoices Image"></img>
              <Link to="/products/business-forms/invoices/">Invoices</Link>
            </div>
            <div className={styles.card}>
              <img src={sales_forms} alt="Sales forms Image"></img>
              <Link to="/products/business-forms/sales-forms/">
                Sales Forms
              </Link>
            </div>
            <div className={styles.card}>
              <img src={work_orders} alt="Work orders Image"></img>
              <Link to="/products/business-forms/work-orders/">
                Work Orders
              </Link>
            </div>
            <div className={styles.card}>
              <img src={proposals} alt="Proposals Image"></img>
              <Link to="/products/business-forms/proposals/">Proposals</Link>
            </div>
            <div className={styles.card}>
              <img src={time_tickets} alt="Time tickets Image"></img>
              <Link to="/products/business-forms/time-tickets/">
                Time Tickets
              </Link>
            </div>
            <div className={styles.card}>
              <img src={purchase_orders} alt="Purchase order forms Image"></img>
              <Link to="/products/business-forms/purchase-order-forms/">
                Purchase Order Forms
              </Link>
            </div>
            <div className={styles.card}>
              <img src={gift_certificates} alt="Gift certificates Image"></img>
              <Link to="/products/business-forms/gift-certificates/">
                Gift Certificates
              </Link>
            </div>
            <div className={styles.card}>
              <img src={receipt_books} alt="Receipt books Image"></img>
              <Link to="/products/business-forms/receipt-books/">
                Receipt Books
              </Link>
            </div>
            <div className={styles.card}>
              <img src={shipping_products} alt="Shipping products Image"></img>
              <Link to="/products/business-forms/shipping-products/">
                Shipping Products
              </Link>
            </div>
            <div className={styles.card}>
              <img src={sales_forms} alt="Manual forms Image"></img>
              <Link to="/products/business-forms/manual-forms/">
                Manual Forms
              </Link>
            </div>
            <div className={styles.card}>
              <img src={custom_forms} alt="Custom forms Image"></img>
              <Link to="/products/custom-printing/">Custom Forms</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
