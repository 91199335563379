import React from "react";
import Layout from "../../../other/layout";
import BusinessForms from "../../../components/AC-StaticPages/products/BusinessForms";
import SEO from "../../../other/seo";

export default function businessForms() {
  return (
    <Layout>
      <SEO title="Business Forms" />

      <BusinessForms />
    </Layout>
  );
}
